<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Peralatan Inspeksi'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nama_peralatan_label"
                  label="Nama peralatan :"
                  label-for="nama_peralatan"
                >
                  <b-form-input
                    id="nama_pabrik"
                    v-model="$v.form.nama_peralatan.$model"
                    type="text"
                    placeholder="Enter Text..."
                    :state="validateState('nama_peralatan')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="nama_peralatan_feedback">
                    Nama Peralatan Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="model_label"
                  label="Model :"
                  label-for="model"
                >
                  <b-form-input
                    id="model"
                    v-model="form.model"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="fungsi_label"
                  label="Fungsi :"
                  label-for="fungsi"
                >
                  <vue-editor id="fungsi" v-model="form.fungsi"></vue-editor>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="tahun_dibuat_label"
                  label="Tahun Dibuat :"
                  label-for="tahun_dibuat"
                >
                  <b-form-input
                    id="tahun_dibuat"
                    v-model="form.tahun_dibuat"
                    type="number"
                    placeholder="Enter Number..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nomor_inventaris_label"
                  label="Nomor Inventaris :"
                  label-for="nomor_inventaris"
                >
                  <b-form-input
                    id="nomor_inventaris"
                    v-model="form.nomor_inventaris"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="foto_label"
                  label="Foto :"
                  label-for="foto"
                >
                  <b-form-file
                    id="foto"
                    v-model="form.foto"
                    placeholder="Choose a photo or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="manual_book_label"
                  label="Manual Book :"
                  label-for="manual_book"
                >
                  <b-form-file
                    id="manual_book"
                    v-model="form.manual_book"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="manufacture_label"
                  label="Manufacture :"
                  label-for="manufacture"
                >
                  <b-form-input
                    id="manufacture"
                    v-model="form.manufacture"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="spesifikasi_label"
                  label="Spesifikasi :"
                  label-for="spesifikasi"
                >
                  <vue-editor id="spesifikasi" v-model="form.spesifikasi"></vue-editor>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="kategori_label"
                  label="Kategori :"
                  label-for="kategori"
                >
                  <b-form-select
                    id="kategori"
                    v-model="form.kategori"
                    :options="optionsKategori"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="tanggal_pembelian_label"
                  label="Tanggal Pembelian :"
                  label-for="tanggal_pembelian"
                >
                  <b-form-input
                    id="tanggal_pembelian"
                    v-model="form.tanggal_pembelian"
                    type="date"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="link_label"
                  label="Link :"
                  label-for="link"
                >
                  <b-form-input
                    id="link"
                    v-model="form.link"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="instruksi_kerja_label"
                  label="Instruksi Kerja :"
                  label-for="instruksi_kerja"
                >
                  <b-form-input
                    id="instruksi_kerja"
                    v-model="form.instruksi_kerja"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="kuantity_peralatan_label"
                  label="Kuantitas Peralatan :"
                  label-for="kuantity_peralatan"
                >
                  <b-form-input
                    id="kuantity_peralatan"
                    v-model="form.kuantity_peralatan"
                    type="number"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Status Investasi"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    id="IsInvestasi"
                    v-model="form.IsInvestasi"
                    :aria-describedby="ariaDescribedby"
                    name="IsInvestasi"
                  >
                    <b-form-radio :value="true">Investasi</b-form-radio>
                    <b-form-radio :value="false">Non Investasi</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/peralataninspeksi')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import { VueEditor } from 'vue2-editor'
import $ from 'jquery'

export default {
  mixins: [validationMixin],
  name: 'addperalataninspeksi',
  data() {
    return {
      form: {
        nama_peralatan: null,
        model: null,
        fungsi: null,
        tahun_dibuat: null,
        nomor_inventaris: null,
        foto: null,
        manual_book: null,
        manufacture: null,
        spesifikasi: null,
        kategori: null,
        tanggal_pembelian: null,
        link: null,
        instruksi_kerja: null,
        kuantity_peralatan: null,
        IsInvestasi: null,
      },
      optionsKategori: [
        { value: null, text: '-- Pilih Kategori --' },
        { value: 'Ultrasonic Thickness', text: 'Ultrasonic Thickness' },
        { value: 'Ultrasonic Flaw Detector', text: 'Ultrasonic Flaw Detector' },
        { value: 'Ultrasonic Phased Array', text: 'Ultrasonic Phased Array' },
        { value: 'Thermograph', text: 'Thermograph' },
        { value: 'Eddy Current', text: 'Eddy Current' },
        { value: 'Borescope', text: 'Borescope' },
        { value: 'Magnetic Particle', text: 'Magnetic Particle' },
        { value: 'Hardness Tester', text: 'Hardness Tester' },
        { value: 'Lain-lain', text: 'Lain-lain' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],

      show: true,
      isBusy: false,
      loading: false,
    }
  },
  validations: {
    form: {
      nama_peralatan: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    VueEditor,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Peralatan Inspeksi', route: '/peralataninspeksi' },
      { title: 'Create' },
    ])
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this
      self.loading = true
      const nama_peralatan = self.$v.form.nama_peralatan.$model
      const model = self.form.model
      const fungsi = self.form.fungsi
      const tahun_dibuat = self.form.tahun_dibuat
      const nomor_inventaris = self.form.nomor_inventaris
      const foto = self.form.foto
      const manual_book = self.form.manual_book
      const manufacture = self.form.manufacture
      const spesifikasi = self.form.spesifikasi
      const kategori = self.form.kategori
      const tanggal_pembelian = self.form.tanggal_pembelian
      const link = self.form.link
      const instruksi_kerja = self.form.instruksi_kerja
      const kuantity_peralatan = self.form.kuantity_peralatan
      const IsInvestasi = self.form.IsInvestasi

      const dataku = new FormData()
      dataku.append('nama_peralatan', nama_peralatan)
      dataku.append('model', model)
      dataku.append('fungsi', fungsi)
      dataku.append('tahun_dibuat', tahun_dibuat)
      dataku.append('nomor_inventaris', nomor_inventaris)
      if (foto) {
        dataku.append('foto', foto)
      }
      if (manual_book) {
        dataku.append('manual_book', manual_book)
      }
      dataku.append('manufacture', manufacture)
      dataku.append('spesifikasi', spesifikasi)
      dataku.append('kategori', kategori)
      dataku.append('tanggal_pembelian', tanggal_pembelian)
      dataku.append('link', link)
      dataku.append('instruksi_kerja', instruksi_kerja)
      dataku.append('kuantity_peralatan', kuantity_peralatan)
      dataku.append('IsInvestasi', IsInvestasi)

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/peralataninspeksi/store',
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/peralataninspeksi')
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
